export const SliderData = [
    {
        image: 'https://m.media-amazon.com/images/I/61ibGUwvB1L._SX3000_.jpg'
    },
    {
        image: 'https://m.media-amazon.com/images/I/61k05KfQvoL._SX3000_.jpg%22%20class=%22_cropped-image-map_style_fluidLandscapeImage__3eTVC%20_cropped-image-map_style_fluidImage__iJ3aE%22%20data-a-hires=%22https://m.media-amazon.com/images/I/61k05KfQvoL._SX3000_.jpg'
    },
    {
        image: 'https://m.media-amazon.com/images/I/61rJdKTKvhL._SX3000_.jpg%22%20class=%22_cropped-image-map_style_fluidLandscapeImage__3eTVC%20_cropped-image-map_style_fluidImage__iJ3aE%22%20data-a-hires=%22https://m.media-amazon.com/images/I/61rJdKTKvhL._SX3000_.jpg'
    },
    {
        image:"https://m.media-amazon.com/images/I/719UdnQ4G5L._SX3000_.jpg"
    },
]
